import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MapsAPILoader } from '@agm/core';
import Swal from 'sweetalert2';
import { FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import * as io from 'socket.io-client';
import { ToastrManager } from 'ng6-toastr-notifications';
import { HttpsService } from '../http/http.service';
import { apiList } from '../http/api-list';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})

export class CommonService {

  pageTitle: any = ''
  socket: any;
  token: string;

  adminProfile: any = '';
  exportEvent: Subject<boolean> = new Subject();
  geoCoder;
  zoom: number;
  address: string;
  lat: any;
  long: any;
  fullAddress: BehaviorSubject<any> = new BehaviorSubject({
    address: null,
    city: null,
    state: null,
    country: null,
    latitude: 0,
    longitude: 0
  });
  user: any;
  socketUrl=environment.imageUrl;
  subscribtion: any;
  soundOn: any;
  interval: NodeJS.Timer;


  constructor(private router: Router,
    private mapsAPILoader: MapsAPILoader,
    private spinner: NgxSpinnerService,private _toastrService: ToastrManager,private http: HttpsService,private apiLis: apiList) {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
    });
  }

  presentsToast(type: any, message: any) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      timerProgressBar: false,
      showCancelButton: false,
      showCloseButton: true,
      showConfirmButton: false,
      timer: 1500,
    });
    Toast.fire({
      title: message,
      icon: type
    });
  }

  confirmToast() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
      }
    })
  }

  openDialog(type: any, message: any) {
    Swal.fire({
      title: 'Error!',
      text: message,
      icon: type,
      width: '300',
      imageHeight: '10',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Close'
    })
  }

  forceLogout() {
    this.presentsToast('warning', 'You have been logged out for security purpose.');
    this.router.navigateByUrl('auth/login');
  }

  noSpace(event) {
    if (event.which === 32 && !event.target.value.length)
      event.preventDefault();
  }

  validateImageFile(fileData) {
    if (fileData.type == 'image/jpeg' ||
      fileData.type == 'image/png' ||
      fileData.type == 'image/jpg' ||
      fileData.type == 'image/svg' ||
      fileData.type == 'image/webp' ||
      fileData.type == 'image/gif') {
      return true;
    } else {
      this.presentsToast('error', 'Please upload a valid file!');
      return false;
    }
  }

  validateImageAndVideoFile(fileData) {
    if (fileData.type == 'image/jpeg' ||
      fileData.type == 'image/png' ||
      fileData.type == 'image/jpg' ||
      fileData.type == 'image/svg' ||
      fileData.type == 'image/webp' ||
      fileData.type == 'image/gif' ||
      fileData.type == 'video/mp4' ||
      fileData.type == 'video/3gp' ||
      fileData.type == 'video/mkv' ||
      fileData.type == 'video/webm' ||
      fileData.type == 'video/m4v' ||
      fileData.type == 'video/svi' ||
      fileData.type == 'video/3g2') {
      return true;
    } else {
      this.presentsToast('error', 'Please upload a valid file!');
      return false;
    }
  }

  validateDocFile(fileData) {
    if(fileData != undefined || fileData != null || fileData != '') {
      if (fileData.type == 'doc' ||
        fileData.type == 'docx' ||
        fileData.type == 'ppt' ||
        fileData.type == 'pptx' ||
        fileData.type == 'txt' ||
        fileData.type == 'pdf' ||
        fileData.type == 'application/pdf' ||
        fileData.type == 'application/msword' ||
        fileData.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        fileData.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        return true;
      } else {
        this.presentsToast('error', 'Please upload a valid file!');
        return false;
      }
    }
  }

  checkType0(file_type) {
    if (!(file_type == '' || file_type == null || file_type == undefined)) {
      let split0 = file_type.split("data:");
      let split1 = split0[1].split("/");
      return split1[0];
    }
  }

  checkType(file_type) {
    if (!(file_type == '' || file_type == null || file_type == undefined)) {
      let split = file_type.split("/");
      return split[0]
    }
  }

  setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.zoom = 8;
        this.getAddress(position.coords.latitude, position.coords.longitude, null);
      });
    }
  }

  getAddress(lat, lng, formatted_address) {
    this.lat = lat;
    this.long = lng;
    this.geoCoder.geocode({ 'location': { lat: lat, lng: lng } }, (results, status) => {
      if (status === 'OK') {
        if (results.length > 0) {
          var isAddSimilar = false;
          let length = results[0].address_components.length;
          results.forEach(addresses => {
            if (addresses.formatted_address === formatted_address && !isAddSimilar) {
              isAddSimilar = true;
              this.fetchAddress(addresses.address_components);
              return false;
            }
          });
          if (!isAddSimilar) {
            this.fetchAddress(results[0].address_components);
          }
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  fetchAddress(addressArr) {
    var city = null;
    var state = null;
    var zipCode = null;
    var country = null;
    addressArr.forEach(element => {
      if (element.types.includes("locality") || element.types.includes("postal_town") || element.types.includes("sublocality") ||
        element.types.includes("sublocality_level_1") || element.types.includes("sublocality_level_2") || element.types.includes("sublocality_level_3") ||
        element.types.includes("sublocality_level_4") || element.types.includes("sublocality_level_5")) {
        city = element.long_name;
      }
      if (element.types.includes("administrative_area_level_1") || element.types.includes("administrative_area_level_2") ||
        element.types.includes("administrative_area_level_3") || element.types.includes("administrative_area_level_4") || element.types.includes("administrative_area_level_5")) {
        state = element.long_name;
      }
      if (element.types.includes("postal_code")) {
        zipCode = element.long_name;
      }
      if (element.types.includes("country")) {
        country = element.long_name;
      }
    });
    this.fullAddress.next({
      address: city + ', ' + state + ', ' + country,
      city: city,
      state: state,
      country: country,
      latitude: this.lat,
      longitude: this.long
    })
  }

  onSelectFiles(files) {
    let selectedFiles = [];
    return new Promise((resolve, reject) => {
      Array.prototype.forEach.call(files, (child) => {
        let check = this.validateImageFile(child);

        if (check) {
          resolve(selectedFiles);
          var reader = new FileReader();
          reader.readAsDataURL(child);
          reader.onload = (event) => {
            let obj = {};
            obj['url'] = reader.result as string;
            obj['type'] = this.checkType0(reader.result as string);
            selectedFiles.push(obj);
          };
        } else {
          reject(false)
        }
      })
    })
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }
      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  checkExpVal(event, exp) {
    var expVal: any[] = [];
    if (!(exp == undefined || exp == null || exp == "")) {
      expVal = (exp + "").split(".");
    }
    const charCode = event.which ? event.which : event.keyCode;
    if (
      charCode == 45 ||
      charCode == 101 || charCode < 48 || charCode > 57 ||
      (expVal.length > 1 && expVal[1].toString().length > 1)
    ) {
      if(charCode==46){
        return true;
      }
      return false;
    }
  }

  showSpinner() {
    this.spinner.show();
  }

  hideSpinner() {
    this.spinner.hide();
  }


  to_fixed(val) {
    var str =  val.toString();
    var split = str.split('.');
    if(split.length == 2) {
      return parseFloat(str).toFixed(2);
    } else {
      return str;
    }
  }

  successMsg(message) {
    this._toastrService.successToastr(message, '', {
      closeButton: false,
      toastTimeout: 2500,
      progressBar: true,
      progressAnimation: "decreasing",
      maxShown: 1
    });
  }



  errorMsg(message) {
    this._toastrService.errorToastr(message, '', {
      closeButton: false,
      toastTimeout: 2500,
      progressBar: true,
      progressAnimation: "decreasing",
      maxShown: 1
    });
  } 

  connection() {
    this.token = !(localStorage.binge_Authorization == '' || localStorage.binge_Authorization == undefined || localStorage.binge_Authorization == null ) ? localStorage.binge_Authorization : '';
    if (localStorage.getItem('bingeUser') && this.token !=='') {
      this.user = JSON.parse(localStorage.getItem('bingeUser'));
      this.socket = io.connect(this.apiLis.socketUrl, {
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 3000,
        reconnectionAttempts: Infinity,
        // transports: ['websocket', 'polling', 'flashsocket'],
        query: {
          token: this.token
        }

      });
      this.socket.on('connect', (res:any) => {
        const data = {
          userId: this.user['_id']
        };
        this.socket.emit('addUser', data);
        var index=0
        if(this.subscribtion==undefined){
         this.subscribtion =  this.getUserNotificationListener().subscribe((res:any)=>{           
            ++index;
            if(res['type']===2){
              this.playAudio();
              this.order()
              this.successMsg(res['message'])
              this._toastrService.onClickToast().subscribe((resp:any)=>{
                if(resp.message=="NEW_ORDER "){
                  
                  this.navigate();
                }
              })
            
        }
          else if(res['type']===3 && res['message']===7){
            this.playAudio();
            this.order()
            this.errorMsg(res['message'])
            this._toastrService.onClickToast().subscribe((resp:any)=>{
              if(resp.message==7){
                this.navigate();
              }
            })
          }

        this.startTimer();
        })
      }
      });
    
      this.socket.on('discount',(message:any)=>{        
      })

    }
  }
  

  navigate(){
    this.router.navigateByUrl("/pages/booking")
  }

  startTimer() {
    this.soundOn=localStorage.getItem("orderStatus")
    this.interval = setInterval(() => {
     
        if(this.soundOn==1){
          this.playAudio();

        }
      
    },350 * 1000)
  }

  playAudio() {
    setTimeout(() => {
      const audio = new Audio();
      audio.src = 'assets/sounds/notification-sound1.mp3';
      audio.load();
      audio.play();
    });
   
  }

  getUserNotificationListener() {
    return new Observable(observer => {
      this.socket.on('orderNotification', (message:any) => {
        if(message)
        observer.next(message);
      });
    });
  }

  order(){
 var param={}
    this.http.httpPostWithHeader('order', param, false).subscribe((res: any) => {
			if (res.statusCode == 200) {
        this.http.ordersData = res.data
        localStorage.setItem('orderStatus', res.data[0].orderStatus)
			}
		})

  } 

}
