import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = localStorage.binge_Authorization;
    const user = localStorage.bingeUser;
    if (next.routeConfig.path === "component") {
      if (token && user) {
        this.router.navigateByUrl("/pages/dashboard");
        return false;
      } else {
        return true;
      }
    } else {
      if (token !== null && token !== undefined && token !== '') {
        return true;
      } else {
        localStorage.clear();
        this.router.navigateByUrl("/component/login");
        return false;
      }
    }
  }

}
