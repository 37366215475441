import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { AppRoutingModule } from './app-routing.module';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SharedModule } from './shared/shared.module';
import { AgmCoreModule } from '@agm/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";

import { AppComponent } from './app.component';
import { ConfirmationModalComponent } from './services/modal/confirmation-modal/confirmation-modal.component';

import { apiList } from './services/http/api-list';
import { HttpInterceptorService } from './services/interceptor/http-interceptor.service';
import { environment } from 'src/environments/environment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { HttpsService } from './services/http/http.service';
import { ToastrModule } from 'ng6-toastr-notifications';


@NgModule({
  declarations: [
    AppComponent,
    ConfirmationModalComponent,
  ],
  imports: [
    BrowserModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    CKEditorModule,
    BrowserAnimationsModule,
    BrowserAnimationsModule,
    SharedModule,
    NgxIntlTelInputModule,
    GooglePlaceModule,
    ToastrModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: environment.apiKey,
      libraries: ['places']
    }),
    NgxSpinnerModule
  ],
  entryComponents: [
    ConfirmationModalComponent
  ],
  providers: [
    NgxUiLoaderService,
    NgxSpinnerService,
    apiList,
    HttpsService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true, },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
