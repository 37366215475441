import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { apiList } from './api-list';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class HttpsService {

  baseUrl: string = environment.backendUrl;
  ordersData: any=[];
  public selectorInput: Subject<boolean> = new Subject();

  constructor(
    private http: HttpClient,
    private apiList: apiList
  ) {
  }

  // Http Post

  httpPost(url: string, params: any): Observable<Object> {
    return this.http.post(this.baseUrl + this.apiList[url], params);
  }

  httpPostWithHeader(url: string, params: any, mime: boolean): Observable<Object> {
    return this.http.post(this.baseUrl + this.apiList[url], params, this.header(mime));
  }

  httpPostWithHeaderAndById(url: string, id, params: any, mime: boolean): Observable<Object> {
    return this.http.post(this.baseUrl + this.apiList[url] + id + "/", params, this.header(mime));
  }

  httpPostWithHeaderMultiPart(url: string, params: any, mime: boolean): Observable<Object> {
    return this.http.post(this.baseUrl + this.apiList[url], params, this.header1(mime));
  }

  httpPostWithDifferentHeader(url: string, params: any, type: any, ): Observable<Object> {
    return this.http.post(this.baseUrl + this.apiList[url], params, this.differentHeader(type));
  }

  // --------


  // Http Put

  httpPutWithBodyWithoutHeaderById(url: string, id: any, param: any, mime: boolean): Observable<Object> {
    return this.http.put(this.baseUrl + this.apiList[url] + "/" + id, param);
  }

  httpPutWithBodyAndHeader(url: string, param: any, mime: boolean): Observable<Object> {
    return this.http.put(this.baseUrl + this.apiList[url], param, this.header(mime));
  }

  httpPutWithBodyHeaderAndId(url: string, id: any, param: any, mime: boolean): Observable<Object> {
    return this.http.put(this.baseUrl + this.apiList[url] + "/" + id, param, this.header(mime));
  }

  httpPutWithBodyAndDifferentHeader(url: string, param: any, mime: any): Observable<Object> {
    return this.http.put(this.baseUrl + this.apiList[url], param, this.differentHeader(mime));
  }

  // --------


  // Http Get

  httpGet(url: string): Observable<Object> {
    return this.http.get(this.baseUrl + this.apiList[url]);
  }

  httpGetById(url: string, params: any, mime: boolean): Observable<Object> {
    return this.http.get(this.baseUrl + this.apiList[url] + '/' + params, this.header(mime));
  }

  httpGetWithQParam(url: string, params: any, mime: boolean): Observable<Object> {
    return this.http.get(this.baseUrl + this.apiList[url] + "?" + params, this.header(mime));
  }

  httpGetWithHeaderOnly(url: string, mime: boolean): Observable<Object> {
    return this.http.get(this.baseUrl + this.apiList[url], this.header(mime));
  }



  // --------


  // Https Delete

  httpDeleteWithHeaderById(url: string, params: any, mime: boolean): Observable<Object> {
    return this.http.delete(this.baseUrl + this.apiList[url] + "/" + params, this.header(mime));
  }

  httpDeleteWithHeaderOnly(url: string, mime: boolean): Observable<Object> {
    return this.http.delete(this.baseUrl + this.apiList[url], this.header(mime));
  }

  // --------

  upload(files) {
    let medias: any[] = [];
    return new Promise((resolve, reject) => {
      let formData: FormData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i]);
      }
      this.httpPostWithHeader("uploadMedia", formData, true)
        .subscribe((res: any) => {
          if (res.statusCode == 200) {
            medias.push(res.data.file);
            // res.data.forEach(element => {
            //   medias.push(element);
            // });
            resolve(medias);
          }
        }, ((error) => {
          reject(false)
        }));
    });
  }

  header(mime) {
    if (localStorage.binge_Authorization && mime) {
      let headers = new HttpHeaders({
        // "mimeType": "multipart/form-data",
        authorization: 'Bearer ' + localStorage.binge_Authorization
      });
      const option = {
        headers
      };
      return option;
    }
    if (localStorage.binge_Authorization && !mime) {
      let headers = new HttpHeaders({
        'content-type': 'application/json',
        // "mimeType": "multipart/form-data",
        authorization: 'Bearer ' + localStorage.binge_Authorization
      });
      const option = {
        headers
      };
      return option;
    }
  }

  header1(mime) {
    if (localStorage.binge_Authorization && !mime) {
      let headers = new HttpHeaders({
        "mimeType": "multipart/form-data",
        authorization: 'Bearer ' + localStorage.binge_Authorization       
      });
      const option = {
        headers
      };
      return option;
    }
  }

  differentHeader(tokenType) {
    if (tokenType == 'reset') {
      let headers = new HttpHeaders({
        'content-type': 'application/json',
        authorization: 'Bearer ' + localStorage.resetToken
      });
      const option = {
        headers
      };
      return option;
    }
    if (tokenType == 'signUp') {
      let headers = new HttpHeaders({
        'content-type': 'application/json',
        authorization: 'Bearer ' + localStorage.signUpToken
      });
      const option = {
        headers
      };
      return option;
    }
  }

}