import { environment } from "src/environments/environment";

export class apiList {
  socketUrl = environment.imageUrl;

    login = "/brand/login";
    signUp = "/brand/signup";
    verifyOtp = "/brand/verifyOtp";
    sendOtp = "/sendOtp";
    kitchens = "/brand/kitchen"
    uploadMedia = "/upload"
    addOnsCategory = "/brand/addOnsCategory"
    addOns = "/brand/addOns";
    foodItems = "/brand/foodItems";
    searchFoodItems = "/brand/searchFoodItems";
    foodCategory = "/brand/foodCategory";
    searchFoodCategory = "/brand/searchFoodCategory";
    searchKitchen = "/brand/searchkitchen";
    searchAddOnsCategory = "/brand/searchAddOnsCategory";
    searchSubAddOns = "/brand/searchAddOns";
    tableBookings = "/brand/tableBookings";
    agents = "/brand/agent";
    searchAgent = "/brand/searchAgent";
    updateProfile = "/brand/updateProfile";
    order = "/brand/order";
    searchOrder = "/brand/searchOrder";
    addOnsInventory ="/brand/addOnsInventory";
    reviews = "/brand/reviews";
    changePassword = "/brand/changePassword";
    overAllEarning = "/brand/overAllEarning";
    revenue = "/brand/revenue";
    createOrder = "/brand/createOrder";
    dashboard = "/brand/dashboard";
    revenueChart = "/brand/earning";
    kitchenWiseReport = "/brand/kitchenOverAllEarning";
    addExistingAddOns = "/brand/addExistingAddOns";
    uploadFooditems = "/brand/bulkUploadFooditems";
    uploadFoodCategory = "/brand/bulkUploadFoodCategory";
    uploadAddOns = "/brand/bulkUploadAddOns";
    uploadAddOnsCategory = "/brand/bulkUploadAddOnsCategory";
    notifications = "/brand/notifications";
    addInventory = "/brand/addInventory";
    forgotPassword = "/brand/forgotPassword";
    resetPassword = "/brand/forgotchangePassword"
}