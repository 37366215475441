import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, finalize, retry, tap } from "rxjs/operators";
import { CommonService } from '../common/common.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  private AUTH_HEADER = "Authorization";
  private token: string = '';
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private commonService: CommonService,
    private router: Router,
    private ngxService: NgxUiLoaderService) {
    if (localStorage.accessToken != undefined || localStorage.accessToken != null) {
      this.token = localStorage.accessToken
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // this.ngxService.start();
    this.commonService.showSpinner();
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status == 403) {
          this.commonService.hideSpinner()
          this.router.navigateByUrl('/component/login');
        }
        if (error.status == 401) {
          this.commonService.hideSpinner()
          this.router.navigateByUrl('/component/login');
          localStorage.clear();
          // 401 handled in auth.interceptor
          // this.commonService.presentsToast('error','you are logout for security purpose.');
        }
        return throwError(error);
      }),
      finalize(() => { this.commonService.hideSpinner()}),
      tap(event => {
        var eventRes = JSON.parse(JSON.stringify(event));
        if (eventRes.body) {
          if (eventRes.body.statusCode === 400) {
            if (eventRes.body.error) {
              this.commonService.presentsToast('error', eventRes.body.error);
              this.commonService.hideSpinner()
              // this.commonService.presentsToast('error',eventRes.body.error);
            } else if (eventRes.body.message) {
              this.commonService.presentsToast('error', eventRes.body.message);
              this.commonService.hideSpinner()
              // this.commonService.presentsToast('error',eventRes.body.message);
            }
          }
        }
      }, error => {
        this.commonService.hideSpinner();
        switch (error.status) {
          case 401:
            /***  Auto LogOut if Api response 401 ** */
            this.commonService.presentsToast('warning', "You have been loggedout for security purpose.");
            this.router.navigateByUrl('/component/login');
            localStorage.clear();
            break;
          case 500:
            /*** If api does not respond  ** */
            this.commonService.presentsToast('warning', 'Api Not Working');
            break;
          case 404:
            /*** If api does not respond  ** */
            this.commonService.presentsToast('warning', 'Api Not Found!');
            break;

          default:
            if (error.error) {
              error.error.message && this.commonService.presentsToast('error', error.error.message);
              // this.commonService.presentsToast('error',error.error.message);
            } else {
              error.message && this.commonService.presentsToast('error', error.message);
              // this.commonService.presentsToast('error',error.message);
            }
        }

      })
    );
  }
}
