import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common/common.service';
import { environment } from 'src/environments/environment';
declare var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  user: any;
  imageUrl: string = environment.imageUrl;
  perm: any;

  constructor(public router: Router,private  commonService: CommonService
  ) { 
    this.perm=JSON.parse(localStorage.getItem('bingeUser'));
    if(this.perm){
      this.commonService.connection();   
    }
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('bingeUser'));
    $(document).ready(function () {
      $("#sidebar__toggle").on('click', function () {
        $("#sidebar-mini").toggleClass('sidenav-toggled');
        if (document.body.classList.contains('sidenav-toggled')) {
          document.body.classList.remove('sidenav-toggled');
        } else {
          document.body.classList.add('sidenav-toggled');
        }
        // this.document.body.classList.add('sidenav-toggled');
      });
    });
  }

  goToPage(link) {
    this.router.navigate([link])
  }

  logout() {
    var rememberMe = localStorage.getItem('rememberMe');
    var remembermeDetails = localStorage.getItem('userEmail_pass');
    localStorage.clear();
    this.router.navigate(['/component/login']);
    if (!(rememberMe == null || rememberMe == undefined || rememberMe == '')) {
      localStorage.setItem('rememberMe', rememberMe)
    }
    if (!(remembermeDetails == null || remembermeDetails == undefined || remembermeDetails == '')) {
      localStorage.setItem('userEmail_pass', remembermeDetails)
    }
  }

  change(){
    this.router.navigate(['/pages/changePasswrod']);
  }

}


